import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { get, isEmpty, find } from "lodash"
import { Toggle } from "@4cplatform/elements/Forms"
import { P } from "@4cplatform/elements/Typography"
import { ConfirmationModal } from "@4cplatform/elements/Molecules"

// Helpers
import { JourneyContext } from "../../journey.context"
import { DisclosureNotesContext } from "./disclosureNotes.context"

// Components
import Notes from "./disclosureNotes.notes"
import AddEditModal from "./addEditModal"

const Body = ({ data }) => {
  const { noteFormTemplate, noteDisplayTemplate, name, ...restData } = data
  const {
    disclosureNotes,
    disclosureNoteCreating,
    confirmationModal,
    setConfirmationModal,
    deleteLoading
  } = useContext(DisclosureNotesContext)
  const { data: journeyData, formik, isLoading, setDisableNext } = useContext(JourneyContext)

  const isLocked = get(journeyData, "journey.locked", false)
  const isBupaSwitchDisclosures = get(journeyData, "page.key", "") === "BUPA_SWITCH_DISCLOSURES"

  const hasNotesField = !isEmpty(find(disclosureNotes, note => note.field === name))
  const fieldValue = get(formik, `values.${name}`, false)

  useEffect(() => {
    if (fieldValue && !hasNotesField) {
      setDisableNext(true)
    }

    return () => setDisableNext(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue, hasNotesField])

  if (isLoading) return null

  return (
    <>
      <Toggle
        {...restData}
        labelWidth="auto"
        margin="1rem 0"
        formik={formik}
        name={name}
        isDisabled={deleteLoading || disclosureNoteCreating || isLocked || isBupaSwitchDisclosures}
      />
      {fieldValue && <Notes fieldName={name} noteDisplayTemplate={noteDisplayTemplate} />}
      <AddEditModal noteFormTemplate={noteFormTemplate} />
      {confirmationModal.isOpen && (
        <ConfirmationModal
          confirmIcon="cancel"
          confirmText="Yes"
          confirmAppearance="error"
          cancelAppearance="errorGhost"
          onClose={() =>
            setConfirmationModal({
              ...confirmationModal,
              closedSelected: true,
              confirmedSelected: false
            })
          }
          onConfirm={() =>
            setConfirmationModal({
              ...confirmationModal,
              closedSelected: false,
              confirmedSelected: true
            })
          }
        >
          <P>{confirmationModal.warningText}</P>
        </ConfirmationModal>
      )}
    </>
  )
}

Body.defaultProps = {
  data: {}
}

Body.propTypes = {
  data: PropTypes.object
}

export default Body
