import { boolean } from "yup"
import { get } from "lodash"
import { P, List, A } from "@4cplatform/elements/Typography"
import { Toggle } from "@4cplatform/elements/Forms"

// Components
import React from "react"
import FileUploader from "../../../../../FileUploader"
import Audit from "./Audit"

function getModalContent(key) {
  switch (key) {
    case "AVIVA":
      return (
        <P>
          The customer will have temporary underwriting terms applied, until Aviva Health are in
          receipt of a current satisfactory certificate of insurance. <br /> <br /> The temporary
          terms will mean that any pre-existing conditions will be excluded for the customer, until
          we are in receipt of the certificate. <br /> <br /> This will have an impact on the
          customer, should they wish to claim. Claims will be denied for any medical conditions
          which were apparent prior to the start date of the policy. <br /> <br /> When we receive a
          current satisfactory certificate of insurance, the terms will be reviewed and the
          temporary exclusion will be removed. <br /> <br /> Please note, when we receive the
          current certificate of insurance, if the underwriting terms differ to what has been quoted
          & enrolled, the premium may change. If the premium changes, this will be backdated to the
          start of the policy, and we will either refund or collect the difference in premium.
          Please ensure the underwriting you have quoted is accurate before you proceed.
        </P>
      )
    case "BUPA":
      return (
        <>
          <P>
            By proceeding without uploading a valid certificate of insurance, please be aware of the
            following: This quote has been accepted by you & submitted to Bupa. Bupa will not enroll
            the quote until all missing information has been provided. You can do this within 4C by
            revisiting this journey from the "all policies" section and uploading the documents
            there. Alternatively, you can also email all missing information to{" "}
            <A
              href="mailto:personalintermedsales@bupa.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              personalintermedsales@bupa.com
            </A>
          </P>
          <P>
            Bupa may contact you should they require further information. Once all the missing
            information has been submitted and Bupa have enrolled this quote you'll receive a
            confirmation email with the policy number within 10 working days.
          </P>
        </>
      )
    case "AXA":
      return (
        <>
          <P>
            By proceeding without uploading the certificate of insurance, please be aware of the
            following:
          </P>
          <List listType="unordered" name="popup_content_deman_certificate_insurance_exeter">
            <li>
              The customer will have temporary underwriting terms applied, until AXA Health are in
              receipt of the current certificate of insurance
            </li>
            <li>
              The temporary terms will mean that any pre-existing conditions will be excluded for
              the customer, until we are in receipt of the certificate.
            </li>
            <li>
              This will have an impact on the customer, should they wish to claim. Claims will be
              denied for any medical conditions which were apparent prior to the start date of the
              policy.
            </li>
            <li>
              When we receive the current certificate of insurance, the terms will be reviewed and
              the temporary exclusion will be removed.
            </li>
          </List>
          <P>
            Please note, when we receive the current certificate of insurance, if the underwriting
            terms differ to what has been quoted & enrolled, the premium may change. If the premium
            changes, this will be backdated to the start of the policy, and we will either refund or
            collect the difference in premium. Please ensure the underwriting you have quoted is
            accurate before you proceed.
          </P>
        </>
      )
    case "EXETER":
      return (
        <>
          <P>
            By proceeding without uploading the certificate of insurance, please be aware of the
            following:
          </P>
          <List listType="unordered" name="popup_content_deman_certificate_insurance_exeter">
            <li>
              The customer will not be on cover until The Exeter are in receipt of the current
              certificate of insurance
            </li>
            <li>
              This will have an impact on the customer, should they wish to claim. Claims will be
              denied for any medical conditions which were apparent prior to the start date of the
              policy.
            </li>
            <li>
              When we receive the current certificate of insurance, the client will be underwritten.
            </li>
            <li>
              The customer will not be considered for cover until The Exeter are in receipt of the
              current certificate of Insurance
            </li>
          </List>
          <P>
            The customer cannot make a claim until the application has been underwritten, terms
            accepted and policy has started.
          </P>
        </>
      )
    default:
      return null
  }
}

export const config = data => ({
  title: "Demand certificate of Insurance",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "pmc_must_meet_the_following_criteria_text_message",
          component: P,
          componentProps: {
            children:
              "Requested copy of Previous Medical Certificate (PMC) from client. PMC must meet the following criteria:"
          },
          skipDataMap: true
        },
        {
          key: "demand_certificate_of_insurance_criteria_list",
          component: List,
          componentProps: {
            listType: "unordered",
            name: "demand_certificate_of_insurance_criteria_list",
            children: (
              <>
                <li>Most recent certificate</li>
                <li>Letter headed paper</li>
                <li>Confirmation of members covered</li>
                <li>Original start date</li>
                <li>Underwriting method applied</li>
                <li>Confirmation of terms/exclusions</li>
              </>
            )
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "pmc_received_and_meets_criteria",
          initialValue: get(data, "page.data.pmc_received_and_meets_criteria", false),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label: "PMC received and meets above criteria?",
          component: Toggle,
          componentProps: {
            labelWidth: "39rem",
            isHorizontal: true,
            isRequired: true
          }
        }
      ]
    },
    {
      key: "section_3",
      components: [
        {
          key: "pmc_uploader",
          component: FileUploader,
          componentProps: {
            validFileTypes: ".png,.jpg,.jpeg,.pdf",
            fileType: "PMC",
            name: "pmc_files",
            maxFileSize: 6,
            canAllowMultiple: false,
            fileSelectLabel:
              "The PMC should now be uploaded as a single file in .pdf, .png, .jpg, .jpeg format. Max File Size: 6Mb."
          },
          skipDataMap: true
        }
      ]
    }
  ],
  modals: {
    submit: [
      {
        fieldKey: "pmc_received_and_meets_criteria",
        fieldValueTrigger: false,
        onModalAccept: "setFormikValues",
        formikValuesToSet: ["user_agrees_to_proceed_without_upload", true],
        content: getModalContent(get(data, "journey.selected_quote.provider.provider_key"))
      }
    ]
  }
})
