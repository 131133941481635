import React, { useContext } from "react"
import PropTypes from "prop-types"
import { v4 as uuid } from "uuid"
import { get, find, isEmpty } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"
import { Container } from "@4cplatform/elements/Atoms"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { JourneyContext } from "../../journey.context"
import { DisclosureNotesContext } from "./disclosureNotes.context"

// Components
import { Circle } from "./disclosureNotes.styles"

const Notes = ({ fieldName, noteDisplayTemplate: NoteDisplayTemplate }) => {
  const { disclosureNotes, setAddEditModal } = useContext(DisclosureNotesContext)
  const { data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)

  const hasNote = !isEmpty(find(disclosureNotes, note => note.field === fieldName))

  return (
    <Container
      margin="0 0 3rem 0"
      padding="1rem 1rem 0rem 1rem"
      width="100%"
      style={{ borderLeft: `1px solid ${colours.blue}`, position: "relative" }}
    >
      {hasNote &&
        disclosureNotes.map(note => {
          if (note.field === fieldName) {
            return <NoteDisplayTemplate key={uuid()} name={fieldName} note={note} />
          }
          return null
        })}
      {!hasNote && <P colour={colours.red}>Please add a disclosure note to continue.</P>}
      <Button
        name="add_new"
        leadingIcon="plus"
        iconSize="1rem"
        type="inline-button"
        margin="1rem 0 0 0"
        onClick={() =>
          setAddEditModal({
            type: "new",
            isOpen: true,
            field: fieldName,
            noteInitialValues: {}
          })
        }
        isDisabled={isLocked}
      >
        Add {hasNote ? "another" : "a"} disclosure note
      </Button>
      <Circle />
    </Container>
  )
}

export default Notes

Notes.defaultProps = {
  fieldName: "",
  noteDisplayTemplate: null
}

Notes.propTypes = {
  fieldName: PropTypes.string,
  noteDisplayTemplate: PropTypes.any
}
