import styled from "styled-components"

export const PolicyTaskSubmit = styled.div`
  display: flex;
  justify-content: end;
`

export const PolicyTasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
