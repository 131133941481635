import React, { useEffect, useState } from "react"
import { get, isEmpty, remove, map } from "lodash"
import PropTypes from "prop-types"
import moment from "moment"

// Components
import { FileSelect } from "@4cplatform/elements/Forms"
import { H3, A, SmallText, P } from "@4cplatform/elements/Typography"
import { usePost, useDelete } from "@4cplatform/elements/Api"
import { Button } from "@4cplatform/elements/Molecules"
import { useTranslations } from "@4cplatform/elements/Translations"
import { addAlert } from "@4cplatform/elements/Alerts"
import {
  UploadedFilesWrapper,
  UploadedFile,
  UploadedFileMeta,
  ParagraphWrap
} from "../Journey/components/FileUploader/fileUploader.styles"

const PolicyTaskFileUploader = ({
  fileType,
  fileSelectLabel,
  name,
  accept,
  validFileTypes,
  maxFileSize,
  isRequired,
  policy,
  formik
}) => {
  const t = useTranslations()
  const [files, setFiles] = useState([])
  const [fileToUpload, setFileToUpload] = useState(null)
  const [fileToDelete, setFileToDelete] = useState(null)
  const [fileInputRef, setFileInputRef] = useState(null)

  // is invalid due to other fields
  const isInvalidDueToOthers = (() => {
    switch (fileType) {
      case "COMPANY_THIRD_PARTY_PAYER":
        return (
          get(formik, "values.application_form_received_from_client", false) ||
          get(formik, "values.form_filled_out_correctly", false)
        )
      case "DEMAND_COMPANY_THIRD_PARTY_PAYER":
        return true
      case "PMC":
      default:
        return get(formik, "values.pmc_received_and_meets_criteria", false)
    }
  })()
  const hasError = formik.errors[name] && formik.submitCount >= 1 && isInvalidDueToOthers

  const [upload, { loading: uploadLoading }] = usePost({
    endpoint: "/journeys/:journey/files",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    params: {
      journey: get(policy, "journey_slug", "")
    },
    onCompleted: res => {
      if (res.data?.length) {
        for (let i = 0, n = fileToUpload.length; i < n; i++) {
          if (res.data[i].id) fileToUpload[i].id = res.data[i].id
          if (res.data[i].url) fileToUpload[i].url = res.data[i].url
        }
        setFiles(current => [...res.data, ...current])
        fileInputRef.current.value = ""
        formik.setFieldValue(name, res.data[0].id)
        addAlert({
          message: t("FILE_UPLOAD_SUCCESS"),
          type: "success",
          dismissible: true,
          timeout: 5
        })
      }
    },
    onError: () => {
      addAlert({
        message: t("FILE_UPLOAD_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const [onDelete, { loading: deleteLoading }] = useDelete({
    endpoint: "/journeys/:journey/files/:file",
    onCompleted: () => {
      const filteredFiles =
        files.length === 1 ? [] : remove(files, file => file.id !== fileToDelete.id)
      setFiles(filteredFiles)
      if (isEmpty(filteredFiles)) formik.setFieldValue(name, false)

      setFileToDelete(null)
    },
    onError: () => {
      addAlert({
        message: t("FILE_DELETE_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const handleDrop = (inputValue, inputRef) => {
    if (uploadLoading) {
      return
    }

    if (inputValue.length > 1) {
      addAlert({
        message: t("FILE_TOO_MANY_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })

      return
    }

    setFileToUpload(inputValue)
    setFileInputRef(inputRef)
  }

  useEffect(() => {
    if (fileToUpload && fileToUpload.length > 0) {
      const body = new FormData()
      let shouldUpload = false
      for (let i = 0, n = fileToUpload.length; i < n; i++) {
        if (!isEmpty(validFileTypes)) {
          const fileFormat = `.${fileToUpload[i].name.split(".").pop()}`

          const isInValidFormat = validFileTypes.split(",").includes(fileFormat)
          const isValidFileSize = fileToUpload[i].size * 0.000001 <= parseInt(maxFileSize)
          if (isInValidFormat && isValidFileSize) {
            body.append("files[]", fileToUpload[i])
            shouldUpload = true
          } else {
            let errorMessage = ""
            if (!isInValidFormat) errorMessage = `${t("FILE_UPLOAD_ERROR")}: Invalid file format.`
            if (!isValidFileSize)
              errorMessage = `${t(
                "FILE_UPLOAD_ERROR"
              )}: File size is too large. The file must be less than ${maxFileSize}MB`
            addAlert({
              message: errorMessage,
              type: "error",
              dismissible: true,
              timeout: 5
            })
            break
          }
        } else {
          body.append("files[]", fileToUpload[i])
          shouldUpload = true
        }
      }
      if (shouldUpload) {
        body.append("file_type", fileType)
        upload({ body })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload])

  useEffect(() => {
    if (!fileToDelete) return
    onDelete({
      params: {
        journey: get(policy, "journey_slug", ""),
        file: fileToDelete.id
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToDelete])

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    fileNamesObject.files = files.map(file => file.file_name).join(", ") || "-"

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const renderUploadedFiles = () => {
    if (!isEmpty(files)) {
      return map(files, file => {
        if (!file) return
        const fileSize =
          file.size?.toString().length >= 7
            ? `${Math.round(file.size / 1000000)} MB`
            : `${Math.round(file.size / 1000)} KB`
        return (
          <UploadedFile key={file.id}>
            <UploadedFileMeta>
              <A href={file.url} rel="noopener noreferrer" target="_blank" download>
                <ParagraphWrap> {file.file_name}</ParagraphWrap>
              </A>
              <SmallText margin="0">
                {moment.utc(file.created_at).local().format("DD/MM/YYYY HH:mm:ss")}, {fileSize}
              </SmallText>
            </UploadedFileMeta>

            <Button
              onClick={() => setFileToDelete(file)}
              appearance="errorInline"
              trailingIcon="delete"
              isLoading={deleteLoading}
              type="inline-button"
              name="delete_selected_hospital"
              margin="0 1rem 0"
            />
          </UploadedFile>
        )
      })
    }
    return []
  }

  return (
    <>
      <FileSelect
        errorPrefix="none"
        hasError={hasError}
        name={name}
        formik={formik}
        margin="0"
        isRequired={isRequired}
        isDisabled={!isEmpty(files)}
        label={fileSelectLabel}
        isLoading={uploadLoading || deleteLoading}
        accept={accept}
        onDropCallback={handleDrop}
        onUploadCallback={(inputValue, inputRef) => {
          setFileToUpload(inputValue)
          setFileInputRef(inputRef)
        }}
      />
      {!isEmpty(files) && (
        <>
          <H3 margin="3rem 0 2rem 0">Uploaded File</H3>
          <P>To upload a different file, please delete any files below.</P>
          <UploadedFilesWrapper>{renderUploadedFiles()}</UploadedFilesWrapper>
        </>
      )}
    </>
  )
}

PolicyTaskFileUploader.defaultProps = {
  fileType: "",
  fileSelectLabel: "",
  accept: "",
  validFileTypes: "",
  isRequired: true,
  maxFileSize: 6
}

PolicyTaskFileUploader.propTypes = {
  fileType: PropTypes.string,
  name: PropTypes.string,
  fileSelectLabel: PropTypes.string,
  accept: PropTypes.string,
  /**
   * Validate file formats
   */
  validFileTypes: PropTypes.string,
  /**
   * Maximum file size to upload, default to 20 MB
   */

  maxFileSize: PropTypes.number,
  isRequired: PropTypes.bool,
  policy: PropTypes.object,
  formik: PropTypes.object
}
export default PolicyTaskFileUploader

// eslint-disable-next-line no-new-object
const fileNamesObject = new Object()

export { fileNamesObject }
