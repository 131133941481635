import { boolean, number, string } from "yup"
import { get } from "lodash"
import { Input, Select, Toggle, DatePicker } from "@4cplatform/elements/Forms"
import { ComplianceNote } from "@4cplatform/elements/Molecules"
import { H4, P } from "@4cplatform/elements/Typography"
import moment from "moment/moment"
import CurrentPolicyDetails from "./index"
import { getOptions } from "../CurrentPolicyOptions/currentPolicyOptions.helpers"
import ManageLogic from "./manageLogic"

export const config = data => ({
  title: "Current policy",
  cleanBodyOnSubmit: true,
  subtitle:
    "Does the client have an existing live PMI policy in place or one that has lapsed in the past 30 days?",
  sections: [
    {
      key: "current_policy",
      components: [
        {
          component: ManageLogic,
          skipDataMap: true
        },
        {
          key: "cp_current_policy",
          initialValue: get(data, "page.data.cp_current_policy", ""),
          validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
          label: "Does the client have a current policy in place?",
          component: Toggle,
          componentProps: {
            helperTitle: "Current policy",
            helperText:
              "By clicking <strong>Yes</strong> and confirming the client has a live PMI policy in place you will be presented with the option of switching to other providers on the same underwriting terms. By clicking on <strong>No</strong> you will only have the underwriting options available for people who are currently uninsured.",
            isHorizontal: true,
            labelWidth: "38.5rem",
            isRequired: true
          }
        },
        {
          key: "compliance_note",
          component: ComplianceNote,
          condition: {
            type: "formik",
            fieldKey: "cp_current_policy",
            fieldValue: true
          },
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Compliance note</H4>
                <P margin="0">
                  You need to prompt the client that they would need to cancel their existing policy
                  or wait until the term end to start this policy, which will start from the date
                  their cancellation/term ends.
                </P>
              </>
            ),
            type: "error"
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "current_policy_details",
      title: "Current policy details",
      titleHelper:
        "This information can be found on the renewal documents from your existing insurer or certificate of insurance.",
      condition: {
        type: "formik",
        fieldKey: "cp_current_policy",
        fieldValue: true
      },
      components: [
        {
          key: "compliance_note",
          component: ComplianceNote,
          condition: {
            type: "formik",
            fieldKey: "cp_underwriting",
            fieldValue: "Don't know"
          },
          componentProps: {
            children: (
              <>
                <H4 margin="0 0 1rem">Compliance note</H4>
                <P margin="0">
                  If selecting <strong>Don't know</strong> as a current underwriting method, you
                  will only be able to quote for a new policy. If you are looking to quote for a
                  switch policy, please select the current underwriting method before proceeding.
                </P>
              </>
            ),
            type: "info"
          },
          skipDataMap: true
        },
        {
          key: "cp_underwriting",
          initialValue: get(data, "page.data.cp_underwriting", "Don't know"),
          validationSchema: string().nullable(),
          label: "Underwriting type",
          component: Select,
          componentProps: {
            isHorizontal: true,
            options: getOptions("cp_underwriting"),
            labelWidth: "50%",
            margin: "0 0 2rem",
            shouldDisplayEmptyOption: false
          }
        },
        {
          key: "cp_company_or_group_policy",
          initialValue: get(data, "page.data.cp_current_policy", false)
            ? get(data, "page.data.cp_company_or_group_policy", "")
            : "",
          validationSchema: boolean().when("cp_underwriting", {
            is: value => ["MORI", "FMU", "CMORI", "CME", "CPME", "MHD"].includes(value),
            then: schema => schema.required("MISSING_REQUIRED_FIELD"),
            otherwise: schema => schema.notRequired().nullable()
          }),
          label: "Is the current policy a group/company scheme?",
          component: Toggle,
          componentProps: {
            isHorizontal: true,
            isRequired: true,
            labelWidth: "60%"
          },
          condition: {
            type: "formik",
            fieldKey: "cp_underwriting",
            fieldValue: "Don't know",
            comparitor: "==="
          }
        },
        {
          key: "cp_existing_membership_number",
          initialValue: get(data, "page.data.cp_current_policy", false)
            ? get(data, "page.data.cp_existing_membership_number", "")
            : "",
          validationSchema: string().when("cp_company_or_group_policy", {
            is: true,
            then: schema => schema.required("MISSING_REQUIRED_FIELD"),
            otherwise: schema => schema.notRequired().nullable()
          }),
          label: "Existing group/company scheme membership number",
          component: Input,
          condition: {
            type: "formik",
            fieldKey: "cp_company_or_group_policy",
            fieldValue: true
          }
        },
        {
          key: "cp_underwritten_in_uk",
          initialValue: get(data, "page.data.cp_current_policy", false)
            ? get(data, "page.data.cp_underwritten_in_uk", "")
            : "",
          validationSchema: boolean().when("cp_underwriting", {
            is: value => ["MORI", "FMU", "CMORI", "CME", "CPME", "MHD"].includes(value),
            then: schema => schema.required("MISSING_REQUIRED_FIELD"),
            otherwise: schema => schema.notRequired()
          }),
          label: "Is the current policy underwritten in the UK?",
          component: Toggle,
          componentProps: {
            isHorizontal: true,
            isRequired: true,
            labelWidth: "50%",
            errorPrefix: "label",
            errorLabel: "Underwritten in UK"
          },
          condition: {
            type: "formik",
            fieldKey: "cp_underwriting",
            fieldValue: "Don't know",
            comparitor: "==="
          }
        },
        {
          key: "cp_renewal_date",
          initialValue: get(data, "page.data.cp_current_policy", false)
            ? get(data, "page.data.cp_renewal_date", "")
            : "",
          validationSchema: string().when("cp_underwriting", {
            is: value => ["MORI", "FMU", "CMORI", "CME", "CPME", "MHD"].includes(value),
            then: schema => schema.required("MISSING_REQUIRED_FIELD"),
            otherwise: schema => schema.notRequired().nullable()
          }),
          label: "Renewal date of existing policy",
          component: DatePicker,
          componentProps: {
            dateRangeMin: moment().subtract(30, "days").format("DD/MM/YYYY"),
            dateRangeMax: moment().add(1, "year").subtract(1, "day").format("DD/MM/YYYY"),
            isHorizontal: true,
            labelWidth: "50%",
            isRequired: true,
            margin: "0 0 1rem"
          },
          condition: {
            type: "formik",
            fieldKey: "cp_underwriting",
            fieldValue: "Don't know",
            comparitor: "==="
          }
        },
        {
          key: "cp_monthly_cost",
          initialValue: get(data, "page.data.cp_current_policy", false)
            ? get(data, "page.data.cp_monthly_cost", "")
            : "",
          validationSchema: number()
            .positive("INVALID_POSITIVE_NUMBER")
            .when("cp_underwriting", {
              is: value => ["MORI", "FMU", "CMORI", "CME", "CPME", "MHD"].includes(value),
              then: schema => schema.required("MISSING_REQUIRED_FIELD"),
              otherwise: schema => schema.notRequired().nullable()
            }),
          label: "Monthly renewal premium",
          component: Input,
          componentProps: {
            type: "number",
            leadingIcon: "currency-gbp",
            leadingIconType: "prepend",
            placeholder: "Amount",
            isHorizontal: true,
            labelWidth: "50%",
            margin: "0 0 2rem",
            isRequired: true
          },
          condition: {
            type: "formik",
            fieldKey: "cp_underwriting",
            fieldValue: "Don't know",
            comparitor: "==="
          }
        },
        {
          key: "cp_excess",
          initialValue: get(data, "page.data.cp_current_policy", false)
            ? get(data, "page.data.cp_excess", "")
            : "",
          validationSchema: number()
            .integer("INVALID_INTEGER")
            .when("cp_underwriting", {
              is: value => ["MORI", "FMU", "CMORI", "CME", "CPME", "MHD"].includes(value),
              then: schema => schema.required("MISSING_REQUIRED_FIELD"),
              otherwise: schema => schema.notRequired().nullable()
            }),
          label: "Current Excess",
          component: Input,
          componentProps: {
            type: "number",
            leadingIcon: "currency-gbp",
            leadingIconType: "prepend",
            placeholder: "Amount",
            isHorizontal: true,
            labelWidth: "50%",
            margin: "0 0 2rem",
            isRequired: true
          },
          condition: {
            type: "formik",
            fieldKey: "cp_underwriting",
            fieldValue: "Don't know",
            comparitor: "==="
          }
        },
        {
          key: "current_policy_detail",
          component: CurrentPolicyDetails,
          skipDataMap: true,
          condition: {
            type: "formik",
            fieldKey: "cp_underwriting",
            fieldValue: "Don't know",
            comparitor: "==="
          }
        }
      ]
    }
  ]
})
