/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react"
import { capitalize, get } from "lodash"
import { Table } from "@4cplatform/elements/Organisms"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { Toggle } from "@4cplatform/elements/Forms"
import moment from "moment/moment"
import { SmallText } from "@4cplatform/elements/Typography"
import { JourneyContext } from "../../../../../../journey.context"
import { getName } from "../../../../../../../../Helpers"

const BupaQuestionsTable = () => {
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { formik, data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)
  const applicants = get(data, "journey.applicants", [])

  useEffect(() => {
    const bupaQuestions = get(formik.values, "bupa_questions", null)

    if (bupaQuestions === null) {
      applicants.forEach(applicant => {
        const applicantId = get(applicant, "id")

        const fields = [
          "any_treatment_for_prediabetes_or_diabetes_in_the_last_2_years",
          "bmi_between_19_and_24_point_9"
        ]

        fields.forEach(field => {
          const applicantFieldRef = `bupa_questions.applicant_${applicantId}.${field}`

          formik.setFieldValue(applicantFieldRef, get(applicant.answers, field, false))
        })
      })
    }
  }, [formik.values])

  const applicantsLoading = false

  return (
    <>
      <Table
        data={applicants}
        isLoading={applicantsLoading}
        columns={[
          [
            {
              label: "Name",
              data: "first_name",
              minWidth: "180px",
              render: row =>
                getName({
                  data: get(row, "data"),
                  hasTitle: true,
                  globalTitles: GLOBAL_TITLES,
                  hideOtherTitle: true
                })
            },
            {
              label: "Details",
              data: "date_of_birth",
              minWidth: "180px",
              render: row => {
                const dob = moment(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm")
                const age = moment().diff(dob, "years")
                return (
                  <SmallText margin="0">
                    Age {age}
                    &nbsp;&nbsp;
                    {capitalize(get(row, "data.type", "-"))}
                  </SmallText>
                )
              }
            }
          ],
          {
            label: "Have you had any treatment for prediabetes or diabetes in the last 2 years? *",
            data: "bupa_questions.diabetes",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `bupa_questions.applicant_${applicantId}.any_treatment_for_prediabetes_or_diabetes_in_the_last_2_years`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(
                        row,
                        "data.answers.any_treatment_for_prediabetes_or_diabetes_in_the_last_2_years",
                        false
                      )
                    )}
                  />
                </div>
              )
            }
          },
          {
            label: "Is your BMI between 18.5 and 24.9? *",
            data: "bupa_questions.bmi",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `bupa_questions.applicant_${applicantId}.bmi_between_19_and_24_point_9`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(row, "data.answers.bmi_between_19_and_24_point_9", false)
                    )}
                  />
                </div>
              )
            }
          }
        ]}
        name="applicants"
        hasActions={false}
        hasPerPage={false}
        margin="0 0 30px"
      />
    </>
  )
}

BupaQuestionsTable.defaultProps = {}

BupaQuestionsTable.propTypes = {}

export default BupaQuestionsTable
