import { array, object, string, number } from "yup"
import { get } from "lodash"

import ClaimsHistoryTable from "./claimsHistory.table"

export const config = data => ({
  title: "Claims history",
  sections: [
    {
      key: "applicants",
      components: [
        {
          key: "applicants",
          initialValue: get(data, "journey.applicants", []).map(applicant => ({
            applicant_id: get(applicant, "id"),
            years_covered: get(applicant, "data.years_covered", ""),
            claims_last_five_years: get(applicant, "data.claims_last_five_years", "None"),
            date_of_last_claim: get(applicant, "data.date_of_last_claim", "Never")
          })),
          validationSchema: array(
            object({
              applicant_id: number().required("MISSING_REQUIRED_FIELD"),
              years_covered: string().required("MISSING_REQUIRED_FIELD"),
              claims_last_five_years: string().when("years_covered", {
                is: value => value > 0,
                then: schema => schema.required("MISSING_REQUIRED_FIELD"),
                otherwise: schema => schema.notRequired().nullable()
              }),
              date_of_last_claim: string().when("claims_last_five_years", {
                is: value => ["None"].includes(value),
                then: schema => schema.notRequired().nullable(),
                otherwise: schema => schema.required("MISSING_REQUIRED_FIELD")
              })
            })
          )
            .min(1)
            .required("MISSING_REQUIRED_FIELD"),
          component: ClaimsHistoryTable
        }
      ]
    }
  ]
})
