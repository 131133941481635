import React from "react"
import PropTypes from "prop-types"

import { Modal } from "@4cplatform/elements/Molecules"
import { breakpoints } from "@4cplatform/elements/Helpers"
import { PolicyTasksWrapper } from "./policyTask.styles"
import PolicyTask from "./policyTask"

const width = document.body.clientWidth >= breakpoints.medium ? "800px" : "100%"

const PolicyTasksModal = ({ handleCloseModal, onUpdate, policy, policyTasks }) => (
  <Modal width={width} title="Provide onboard policy evidence" onClose={handleCloseModal}>
    <PolicyTasksWrapper>
      {policyTasks.map(policyTask => (
        <PolicyTask
          key={policyTask.id}
          onUpdate={onUpdate}
          policy={policy}
          policyTask={policyTask}
        />
      ))}
    </PolicyTasksWrapper>
  </Modal>
)

PolicyTasksModal.propTypes = {
  handleCloseModal: PropTypes.func,
  onUpdate: PropTypes.func,
  policy: PropTypes.object,
  policyTasks: PropTypes.arrayOf(PropTypes.object)
}

export default PolicyTasksModal
