import React from "react"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Components
import Tasks from "./policies.panel.body.tasks"
import People from "./policies.panel.body.people"
import MedicalHistory from "./policies.panel.body.medicalHistory"
import Disclosures from "./policies.panel.body.disclosures"
import Documents from "./policies.panel.body.documents"

const PoliciesPanelBody = () => (
  <PanelBody>
    <Tasks />
    <People />
    <MedicalHistory />
    <Disclosures />
    <Documents />
  </PanelBody>
)

export default PoliciesPanelBody
