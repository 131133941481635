import React, { useState } from "react"
import { useFormik } from "formik"
import { get, omit } from "lodash"
import PropTypes from "prop-types"
import { number, object, string } from "yup"

import { usePatch } from "@4cplatform/elements/Api"
import { addAlert } from "@4cplatform/elements/Alerts"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { TextArea } from "@4cplatform/elements/Forms"
import { colours } from "@4cplatform/elements/Helpers"
import { Button } from "@4cplatform/elements/Molecules"
import PolicyTaskFileUploader from "./policyTaskFileUploader"
import { PolicyTaskSubmit } from "./policyTask.styles"

const PolicyTask = ({ onUpdate, policy, policyTask }) => {
  const [isComplete, setIsComplete] = useState(policyTask.complete)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [update] = usePatch({
    endpoint: "/policies/:policy/tasks/:task",
    params: {
      policy: get(policy, "slug", ""),
      task: get(policyTask, "id", "")
    },
    onCompleted: () => {
      setIsComplete(true)
      setIsSubmitting(false)

      addAlert({
        dismissible: true,
        message: "Policy evidence updated.",
        timeout: 5,
        type: "success"
      })

      if (typeof onUpdate === "function") {
        onUpdate(policyTask)
      }
    },
    onError: () => {
      setIsSubmitting(false)

      addAlert({
        dismissible: true,
        message: "Error updating policy evidence.",
        timeout: 5,
        type: "error"
      })
    }
  })

  let validationSchema

  if (policyTask.type === "text") {
    validationSchema = object({
      text: string().required("MISSING_REQUIRED_FIELD")
    })
  } else {
    validationSchema = object({
      file_id: number().required("MISSING_REQUIRED_FIELD")
    })
  }

  const formik = useFormik({
    initialValues: {
      code: policyTask.code,
      file_id: null,
      text: ""
    },
    onSubmit: body => {
      // eslint-disable-next-line no-console
      console.log("body", body)

      setIsSubmitting(true)

      update({
        body: omit(body, policyTask.type === "file" ? "text" : "file_id")
      })
    },
    validationSchema
  })

  const { handleSubmit } = formik

  return (
    <>
      {policyTask.type === "file" ? (
        <>
          <div>
            <div style={{ fontSize: "1.6rem", lineHeight: "2rem", marginBottom: "1.5rem" }}>
              {policyTask.message}
            </div>
            <PolicyTaskFileUploader
              fileType={policyTask.code === "ER058" ? "PMC" : "POLICY_TASK"}
              formik={formik}
              maxFileSize={6}
              name="file_id"
              policy={policy}
              style={{ margin: "1rem 0" }}
              validFileTypes=".png,.jpg,.jpeg,.pdf"
            />
          </div>
        </>
      ) : (
        <TextArea formik={formik} label={policyTask.message} name="text" />
      )}
      <PolicyTaskSubmit>
        {!isComplete ? (
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit}
            trailingIcon="check"
            width="fit-content"
          >
            Submit
          </Button>
        ) : (
          <IconWithText
            content="Completed"
            icon="check-circle"
            iconColour={get(colours, "green")}
          />
        )}
      </PolicyTaskSubmit>
    </>
  )
}

PolicyTask.propTypes = {
  onUpdate: PropTypes.func,
  policy: PropTypes.object,
  policyTask: PropTypes.object
}

export default PolicyTask
