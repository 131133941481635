import React, { useContext, useState } from "react"
import { useGet } from "@4cplatform/elements/Api"
import { get } from "lodash"
import { Button, ComplianceNote } from "@4cplatform/elements/Molecules"
import { H4, P } from "@4cplatform/elements/Typography"
import { PoliciesContext } from "./policies.context"
import PolicyTasksModal from "../PolicyTask/policyTasks.modal"

const Tasks = () => {
  const { selectedPolicy, viewData } = useContext(PoliciesContext)
  const [hasIncompletePolicyTasks, setHasIncompletePolicyTasks] = useState(false)
  const [policyTasks, setPolicyTasks] = useState([])
  const [policyTasksOpen, setPolicyTasksOpen] = useState(false)

  const { refetch: refetchPolicyTasks } = useGet({
    endpoint: "/policies/:policy/tasks",
    params: {
      policy: get(selectedPolicy, "slug", "")
    },
    onCompleted: res => {
      const retrievedPolicyTasks = get(res, "data", [])
      setPolicyTasks(retrievedPolicyTasks)
      setHasIncompletePolicyTasks(retrievedPolicyTasks.filter(task => !task.complete).length > 0)
    }
  })

  const handleAddEvidence = () => {
    setPolicyTasksOpen(true)
  }

  const handleEvidenceClose = () => {
    setPolicyTasksOpen(false)
  }

  const onUpdate = () => {
    refetchPolicyTasks()
  }

  return (
    <>
      {policyTasks.length > 0 && (
        <>
          {hasIncompletePolicyTasks && (
            <ComplianceNote type="warning">
              <H4 margin="0 0 1rem">Urgent: Additional evidence required</H4>
              <P margin="0">
                In order for Bupa to automatically complete this policy submission, you must provide
                the evidence requested below. Failure to do this could delay the enrolment of this
                policy.
              </P>
            </ComplianceNote>
          )}
          <Button
            appearance="whiteGhost"
            margin="0 0 2rem"
            onClick={handleAddEvidence}
            trailingIcon="plus"
            width="fit-content"
          >
            Add additional evidence
          </Button>
          {policyTasksOpen && (
            <PolicyTasksModal
              handleCloseModal={handleEvidenceClose}
              onUpdate={onUpdate}
              policy={viewData}
              policyTasks={policyTasks}
            />
          )}
        </>
      )}
    </>
  )
}

export default Tasks
