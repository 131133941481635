import { get } from "lodash"
import { boolean } from "yup"
import { Toggle } from "@4cplatform/elements/Forms"

// Components
import FileUploader from "../../../../../../FileUploader"
import SendDocumentButton from "../../components/SendDocumentButton/sendDocumentButton"
import Audit from "./Audit"

export const config = data => ({
  title: "Demand Company Third Party Payer Form",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          componentProps: {
            isDocumentSent: !!get(data, "page.data.document_notification_sent")
          },
          skipDataMap: true
        },
        {
          key: "document_notification_sent",
          component: SendDocumentButton,
          componentProps: {
            isDocumentSent: !!get(data, "page.data.document_notification_sent")
          },
          skipDataMap: true
        }
      ]
    },
    {
      key: "section_2",
      components: [
        {
          key: "application_form_received_from_client",
          component: Toggle,
          initialValue: get(data, "page.data.application_form_received_from_client", false),
          validationSchema: boolean()
            .oneOf([true], "MUST_BE_TRUE")
            .required("MISSING_REQUIRED_FIELD"),
          label: "Company Third Party Payer form received from client?",
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "50.5rem"
          }
        },
        {
          key: "form_filled_out_correctly",
          component: Toggle,
          initialValue: get(data, "page.data.form_filled_out_correctly", false),
          validationSchema: boolean()
            .oneOf([true], "MUST_BE_TRUE")
            .required("MISSING_REQUIRED_FIELD"),
          label: "Form filled out correctly?",
          componentProps: {
            errorPrefix: "none",
            isHorizontal: true,
            isRequired: true,
            labelWidth: "39.5rem"
          }
        }
      ]
    },
    {
      key: "section_3",
      components: [
        {
          key: "company_third_party_payer_files",
          component: FileUploader,
          componentProps: {
            validFileTypes: ".png,.jpg,.jpeg,.pdf",
            fileType: "DEMAND_COMPANY_THIRD_PARTY_PAYER",
            name: "company_third_party_payer_files",
            maxFileSize: 6,
            canAllowMultiple: false,
            fileSelectLabel:
              "The completed Third Party Payer form pages should now be uploaded as a single file in .pdf, .png, .jpg, .jpeg format. Max File Size: 6Mb.",
            apiEndpointPath: "bupa-company-third-party-payer-files"
          },
          skipDataMap: true
        }
      ]
    }
  ]
})
