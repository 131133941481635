import { useEffect, useContext, useState } from "react"
import { get } from "lodash"

import { JourneyContext } from "../../../../../../journey.context"

const ManageLogic = () => {
  const { data, formik, updateJourneyAuditData, removeFromFormikValidationSchema } =
    useContext(JourneyContext)

  const [tempFormikValue, setTempFormikValues] = useState(get(data, "page.data", null))

  useEffect(() => {
    const cpCurrentPolicy = get(formik, "values.cp_current_policy", "")
    if (!cpCurrentPolicy) {
      updateJourneyAuditData([
        {
          mode: "replace",
          data: [
            {
              name: "Does the client have a current policy in place?",
              value: "No"
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values])

  // Update validation schema and logic when current policy is "No"
  useEffect(() => {
    const cpCurrentPolicy = get(formik, "values.cp_current_policy", "")
    const cpUnderwriting = get(formik, "values.cp_underwriting", "")
    if (cpCurrentPolicy === false || cpUnderwriting === "Don't know") {
      formik.setTouched({}, false)
      const newValue = {
        cp_underwriting: "Don't know",
        cp_current_policy: cpCurrentPolicy
      }
      formik.setValues(newValue)
      const removeFromValidationSchema = fields => {
        fields.forEach(field => removeFromFormikValidationSchema(field))
      }

      removeFromValidationSchema([
        "cp_current_insurer",
        "cp_current_product",
        "cp_current_product_variant",
        "cp_current_hospital_variant"
      ])
    } else if (cpCurrentPolicy === true && cpUnderwriting !== "Don't know") {
      if (tempFormikValue) {
        formik.setValues({
          ...{
            cp_company_or_group_policy: "",
            cp_existing_membership_number: "",
            cp_excess: "",
            cp_monthly_cost: "",
            cp_renewal_date: "",
            cp_underwritten_in_uk: ""
          },
          ...tempFormikValue,
          ...{ cp_underwriting: cpUnderwriting, cp_current_policy: cpCurrentPolicy }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.cp_current_policy, formik.values.cp_underwriting])

  useEffect(() => {
    const cpCurrentPolicy = get(formik, "values.cp_current_policy", "")
    const cpUnderwriting = get(formik, "values.cp_underwriting", "")
    if (cpCurrentPolicy === true && cpUnderwriting !== "Don't know") {
      setTempFormikValues(formik.values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])
  return null
}

export default ManageLogic
